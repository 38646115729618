import { Component, Input, OnChanges } from "@angular/core";
import { AdvancedModel, AdvancedStep } from "./s25ql.search.advanced.criteria.component";
import { SearchService } from "../../services/search/search.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { DropDownItem } from "../../pojo/DropDownItem";

const taskDefaults = {
    states: {
        outstanding: true,
        completed: false,
        denied: false,
        cancelled: false,
        unread: false,
    },
    types: {
        todo: true,
        vcal: true,
        authorization: true,
        fyi: true,
        assignment: true,
    },
    dateBean: [
        {
            step_param: [
                {
                    from_dt: 0,
                    from_dt_type: "number",
                    until_dt: 1,
                    until_dt_type: "number",
                },
            ],
        },
    ],
    contactBeans: [
        {
            step_type_id: 210,
            title: "Assigned To",
            step_param: [{}],
        },
        {
            step_type_id: 210,
            title: "Assigned From",
            step_param: [{}],
        },
    ],
};

@TypeManagerDecorator("s25-ng-ql-search-advanced-task")
@Component({
    selector: "s25-ng-ql-search-advanced-task",
    template: `
        <div>
            <div class="instructions">
                <p>
                    At least one task state and one task type are required, but you may choose any combination of task
                    types or states, or all of them. By default, task searches return only tasks due today.
                </p>
            </div>

            <s25-simple-collapse [headerText]="'Task States'">
                <div class="collapseContainer">
                    <s25-ng-checkbox [(modelValue)]="task.states.outstanding">Outstanding</s25-ng-checkbox>
                    <s25-ng-checkbox [(modelValue)]="task.states.completed">Completed</s25-ng-checkbox>
                    <s25-ng-checkbox [(modelValue)]="task.states.denied">Denied</s25-ng-checkbox>
                    <s25-ng-checkbox [(modelValue)]="task.states.cancelled">Cancelled</s25-ng-checkbox>
                    <hr />
                    <s25-ng-checkbox [(modelValue)]="task.states.unread">Flagged Tasks Only</s25-ng-checkbox>
                </div>
            </s25-simple-collapse>

            <s25-simple-collapse [headerText]="'Task Types'">
                <div class="collapseContainer">
                    <s25-ng-checkbox [(modelValue)]="task.types.todo">To Do Tasks</s25-ng-checkbox>
                    <s25-ng-checkbox [(modelValue)]="task.types.vcal">vCalendar To Do Tasks</s25-ng-checkbox>
                    <s25-ng-checkbox [(modelValue)]="task.types.authorization">Authorization Tasks</s25-ng-checkbox>
                    <s25-ng-checkbox [(modelValue)]="task.types.fyi">Notification FYIs</s25-ng-checkbox>
                    <s25-ng-checkbox [(modelValue)]="task.types.assignment">Assignment Tasks</s25-ng-checkbox>
                </div>
            </s25-simple-collapse>

            <s25-simple-collapse [headerText]="'Dates'">
                <div class="collapseContainer">
                    <div class="">
                        <label for="taskFromDate" class="ngBold">From</label>
                        <div>
                            <div class="flexRow">
                                <s25-ng-radio
                                    [(modelValue)]="task.dateBean[0].step_param[0].from_dt_type"
                                    [name]="'fromDateTask'"
                                    [value]="'number'"
                                    [style.display]="'block'"
                                >
                                    Today +/-
                                </s25-ng-radio>
                                <input
                                    *ngIf="task.dateBean[0].step_param[0].from_dt_type === 'number'"
                                    [(ngModel)]="task.dateBean[0].step_param[0].from_dt_num"
                                    type="number"
                                    class="c-input"
                                    [id]="'taskFromDate'"
                                    (ngModelChange)="task.dateBean[0].step_param[0].from_dt = $event"
                                />
                            </div>
                            <div class="flexRow">
                                <s25-ng-radio
                                    [(modelValue)]="task.dateBean[0].step_param[0].from_dt_type"
                                    [name]="'fromDateTask'"
                                    [value]="'date'"
                                    (modelValueChange)="task.dateBean[0].step_param[0].from_dt_type = 'date'"
                                >
                                    Specific Date</s25-ng-radio
                                >
                                <s25-ng-editable-date
                                    *ngIf="task.dateBean[0].step_param[0].from_dt_type === 'date'"
                                    [(val)]="task.dateBean[0].step_param[0].from_dt_date"
                                    (valChange)="task.dateBean[0].step_param[0].from_dt = $event"
                                    [inputId]="'taskFromDate'"
                                ></s25-ng-editable-date>
                            </div>
                        </div>
                    </div>

                    <div>
                        <label for="taskUntilDate" class="ngBold">Until</label>
                        <div class="flexRow">
                            <s25-ng-radio
                                [(modelValue)]="task.dateBean[0].step_param[0].until_dt_type"
                                [name]="'untilDateTask'"
                                [value]="'number'"
                                [style.display]="'block'"
                                >Day(s) Following</s25-ng-radio
                            >
                            <input
                                *ngIf="task.dateBean[0].step_param[0].until_dt_type === 'number'"
                                [(ngModel)]="task.dateBean[0].step_param[0].until_dt_num"
                                type="number"
                                class="c-input"
                                [id]="'taskUntilDate'"
                                (ngModelChange)="task.dateBean[0].step_param[0].until_dt = $event"
                            />
                        </div>
                        <div class="flexRow">
                            <s25-ng-radio
                                [(modelValue)]="task.dateBean[0].step_param[0].until_dt_type"
                                [name]="'untilDateTask'"
                                [value]="'date'"
                                (modelValueChange)="task.dateBean[0].step_param[0].until_dt_type = 'date'"
                            >
                                Specific Date</s25-ng-radio
                            >
                            <s25-ng-editable-date
                                *ngIf="task.dateBean[0].step_param[0].until_dt_type === 'date'"
                                [(val)]="task.dateBean[0].step_param[0].until_dt_date"
                                [inputId]="'taskUntilDate'"
                                (valChange)="task.dateBean[0].step_param[0].until_dt = $event"
                            ></s25-ng-editable-date>
                        </div>
                    </div>
                </div>
            </s25-simple-collapse>

            <s25-simple-collapse [headerText]="'Assigned To'">
                <div class="collapseContainer">
                    <s25-ng-multiselect-search-criteria
                        [type]="'contacts'"
                        [modelBean]="{ showResult: true }"
                        [selectedItems]="assignedTo"
                        [popoverPlacement]="'right'"
                        (changed)="assignedTo = toDropdownItems($event); task.contactBeans[0].step_param = assignedTo"
                    ></s25-ng-multiselect-search-criteria>
                </div>
            </s25-simple-collapse>

            <s25-simple-collapse [headerText]="'Assigned From'">
                <div class="collapseContainer">
                    <s25-ng-multiselect-search-criteria
                        [type]="'contacts'"
                        [modelBean]="{ showResult: true }"
                        [selectedItems]="assignedFrom"
                        [popoverPlacement]="'right'"
                        (changed)="
                            assignedFrom = toDropdownItems($event); task.contactBeans[1].step_param = assignedFrom
                        "
                    ></s25-ng-multiselect-search-criteria>
                </div>
            </s25-simple-collapse>
        </div>
    `,
    styles: `
        .instructions {
            background-color: #f5f5f5;
            border: 1px solid #ccc;
            padding: 10px;
            margin-bottom: 10px;
            color: #666;
            font-size: 90%;
            line-height: 140%;
        }

        .collapseContainer {
            padding: 1em;
        }

        s25-ng-checkbox {
            display: block;
        }

        .flexRow {
            display: flex;
            gap: 1em;
            flex-wrap: wrap;
        }
    `,
})
export class S25qlSearchAdvancedInputTaskComponent implements OnChanges {
    @Input() model: AdvancedModel;

    get task() {
        return this.model.step[0];
    }
    set task(task: AdvancedStep) {
        this.model.step[0] = task;
    }

    assignedTo: DropDownItem[];
    assignedFrom: DropDownItem[];

    ngOnChanges() {
        this.task = { ...S25Util.deepCopy(taskDefaults), ...this.task };
        const { from_dt, until_dt } = this.task.dateBean[0].step_param[0];
        this.task.dateBean[0].step_param[0] = SearchService.fromUntilHelper(from_dt, until_dt);
        this.assignedTo = this.toDropdownItems(this.task.contactBeans[0].step_param);
        this.assignedFrom = this.toDropdownItems(this.task.contactBeans[1].step_param);
    }

    toDropdownItems(items: { itemId: string | number; itemName: string }[]): DropDownItem[] {
        return items.filter(({ itemId }) => itemId).map(({ itemId, itemName }) => ({ itemId, itemName }));
    }

    validate() {
        return true;
    }
}
